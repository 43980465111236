.App {
  text-align: left;
}

.App-logo {
  height: 30px;
  width: 100px;
}

.App-header {
  background-color: white;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-title {
  color: #61dafb;
  font-family: Arial;
  font-size: 24px;
  margin: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Span-text {
  color: darkkhaki;
  font-family: Arial;
  font-size: 24px;
  text-align: left;
  padding: 4px;
  vertical-align: middle;
  padding-bottom: 4px;
}

.Span-right {
  color: black;
  font-family: Arial;
  font-size: 24px;
  text-align: right;
  vertical-align: top;
  padding: 10px;
  padding-bottom: 4px;

}


.Span-home {
  color: lightgrey;
  font-family: Arial;
  font-size: 24px;
  text-align: left;
  width: 50%;
  padding: 4px;
  vertical-align: left;
  padding-bottom: 4px;

}

.Span-home-title {
  color: #5294CF;
  font-family: Arial;
  font-size: 32px;
  text-align: left;
  width: 60%;
  padding: 20px;
  vertical-align: left;
  padding-bottom: 4px;
}

Span-api-title {
  color: #5294CF;
  font-family: Arial;
  font-size: 32px;
  text-align: left;
  width: 100%;
  padding: 20px;
  vertical-align: left;
  padding-bottom: 4px;
}

.Span-text-desc {
  color: black;
  background: lightyellow;
  font-family: Arial;
  font-size: 24px;
  text-align: left;
  padding: 4px;
  vertical-align: middle;
  padding-bottom: 4px;
  width: 60%;
}


.Video {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.Audio {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 20vh;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Cli {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-left: 40px;
}

code {
  background-color:  #282c34;
  color: cornflowerblue;
  font-size: 18px;
}

a {
  color: lightseagreen ;
}

.MatButton {
  height: 50px;
  width: 100px;
}


.Video-title {
  color: #61dafb;
  font-family: Arial;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  margin: 10px;
}


.Video-category {
  color: #61dafb;
  font-family: Arial;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
}


.Theme-Label {
  font-size: 14px;
  color: white;
  align-items: center;
}

.circle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: red;
  border: 1px solid #000;
  color: white;
  text-align: center;
  font: 20px Arial;
}

.mobile-image {
  border-radius: 10px;
  border-color: black;
  border-style: double;
  width: 260px;
  height: 500px;
}
.db-image {
  border-radius: 10px;
  border-color: black;
  border-style: double;
  width: 90%;
  height: 500px;
}

#####################

#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}



